@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import './variables.scss';


body { color: $primary-font-color; font-family: $primary-font-regular; font-weight: $fontWeight-default; }

.color-primary { color: $primary-font-color; }
.color-secondary { color: $secondary-font-color; }

.font-primary { font-family: $primary-font-regular; font-weight: $fontWeight-default; }
.font-primary-italic { font-family: $primary-font-italic; font-weight: $fontWeight-default; }
.font-primary-bold { font-family: $primary-font-bold; font-weight: $fontWeight-bold; }
.font-primary-bolditalic { font-family: $primary-font-bolditalic; font-weight: $fontWeight-bold; }

.mat-ripple { display: none; }

.mat-error { color: $color-error; font-family: $primary-font-bold; font-weight: $fontWeight-bold; text-transform: uppercase; font-size: $fontSize-sm; line-height: $lineHeight-sm; }

button.mat-primary {
    background: $secondary-font-color !important; border: $border-width solid $secondary-font-color; color: $color-light; border-radius: $border-radius; width: auto; padding: 1.5rem; height: auto; max-width: 100%; white-space: break-spaces; min-width: 14rem;
    span.mat-button-wrapper {
        font-family: $primary-font-bold; font-weight: $fontWeight-bold; font-size: $fontSize-default; line-height: $lineHeight-xs; letter-spacing: 0.09375rem; text-transform: uppercase; display: flex; align-items: center; justify-content: center;
    }
    .btn-icon { margin-left: 2rem; }
    .icon-disabled { display: none; }
    &:hover {
        background: $primary-blue !important; border-color: $color-dark-grey ;
        span.mat-button-wrapper { color: $color-dark; }
        .icon-active { display: none; }
        .icon-disabled { display: inherit; }
    }
    &:active {
        background: $primary-font-color !important; border-color: $primary-font-color;
        span.mat-button-wrapper { color: $color-light; }
        .icon-disabled { display: none; }
        .icon-active { display: inherit; }
    }
    &.mat-button-disabled {
        background: $color-light-grey !important; border-color: $color-dark-grey;
        span.mat-button-wrapper { color: $color-dark-grey; }
        .icon-active { display: none; }
        .icon-disabled { display: inherit; }
    }
}
button.mat-secondary {
    background: $color-light; border: $border-width solid $color-dark-grey; color: $primary-font-color; border-radius: $border-radius; width: auto; padding: 1.5rem; cursor: pointer; height: auto; max-width: 100%; white-space: break-spaces; min-width: 14rem;
    span.mat-button-wrapper {
        font-family: $primary-font-bold; font-weight: $fontWeight-bold; font-size: $fontSize-default; line-height: $lineHeight-xs; letter-spacing: 0.09375rem; text-transform: uppercase; color: $primary-font-color; display: flex; align-items: center; justify-content: center;
    }
    .btn-icon { margin-left: 2rem; }
    .icon-active { display: none; }
    .icon-disabled { display: none; }
    &:hover {
        background: $secondary-font-color; border-color: $color-dark-grey  !important;
        span.mat-button-wrapper { color: $color-light; }
        .icon-normal { display: none; }
        .icon-active { display: inherit; }
        .icon-disabled { display: none; }
    }
    &:active {
        background: $primary-font-color; border-color: $primary-font-color;
        span.mat-button-wrapper { color: $color-light; }
        .icon-normal { display: none; }
        .icon-active { display: inherit; }
        .icon-disabled { display: none; }
    }
    &.mat-button-disabled {
        background: $color-light-grey !important; border-color: $color-dark-grey !important;
        span.mat-button-wrapper { color: $color-dark-grey; }
        .icon-active { display: none; }
        .icon-normal { display: none; }
        .icon-disabled { display: inherit; }
    }
}

@media (min-width: $mobile) {
    button.mat-secondary { min-width: 18.75rem; }
}

@media (min-width: $max-mobile-screen) and (max-width: $small-desktop) {
    button.mat-primary {
        .btn-icon { display: none!important; }
    }
    button.mat-secondary {
        .btn-icon { display: none!important; }
    }
}
@media (max-width: $mobile) {
    button.mat-primary {
        .btn-icon { display: none!important; }
    }
    button.mat-secondary {
        .btn-icon { display: none!important; }
    }
}

.mat-form-field-type-mat-select {
    .mat-form-field-wrapper {
        .mat-form-field-flex {
            padding: 0 1rem;
            .mat-select-value-text { font-size: 1rem; line-height: 1.5rem; font-weight: 400; font-family: 'PTSansRegular'; color: #606363; }
            .mat-select-arrow-wrapper {
                height: 1.5rem; width: 1.5rem; justify-content: center; transform: inherit;
                .mat-select-arrow { width: 0.75rem; height: 0.5rem; border: 0; background: url("/assets/images/arrow-blue.svg") no-repeat; background-size: contain; }
            }
        }
    }
}

.mat-select-panel-wrap {
    .mat-select-panel {
        max-height: unset; box-shadow: none; border: $border-width solid $secondary-font-color; border-radius: 0; padding: $gap-xs; max-width: 16.875rem!important; min-width: 16.875rem!important;

        .mat-option {
            font-size: $fontSize-default; line-height: $lineHeight-default; padding: $gap-xs; color: $secondary-font-grey; font-family: $primary-font-regular;
            &.mat-active { color: $secondary-font-color; font-family: $primary-font-bold; background: none; }
            &:hover:not(.mat-active) { background: $secondary-grey; }
        }
    }
}

.mat-checkbox .mat-checkbox-inner-container {
    width: 1.125rem; height: 1.125rem;
    .mat-checkbox-frame { border-radius: 0; border-color: $secondary-font-color; }
}
.mat-checkbox .mat-checkbox-label { color: $secondary-font-color; }
.mat-checkbox .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $color-light; height: calc(100% - 0.25rem); width: calc(100% - 0.25rem); top: 0.125rem; left: 0.125rem;
    .mat-checkbox-checkmark {
        fill: $secondary-font-color;
        .mat-checkbox-checkmark-path { stroke: $secondary-font-color !important; }
    }
}
.mat-checkbox.mat-checkbox-disabled {
    .mat-checkbox-inner-container {
        .mat-checkbox-frame { border-color: $color-dark-grey; }
    }
    .mat-checkbox-background { background-color: $color-light-grey; }
    .mat-checkbox-label { color: $color-dark-grey; }
}

table {
    width: 100%; overflow: hidden;
    .mat-header-row {
        height: 3.75rem; box-shadow: 0 $border-radius $border-radius rgba(0, 0, 0, 0.1); transform: scale(1);
        .mat-header-cell {
            font-size: $fontSize-lg; line-height: $lineHeight-lg; font-family: $primary-font-bold; color: $primary-font-color; background: $primary-web-blue;
            .mat-checkbox-label { font-size: $fontSize-lg; line-height: $lineHeight-lg; font-family: $primary-font-bold; color: $primary-font-color; }
        }
    }

    thead {
        tr.mat-header-row {
            th.mat-header-cell { border: none; }
        }
    }

    tbody {
        tr.mat-row {
            height: 3.5rem;
            td.mat-cell { border-color: $secondary-web-blue; border-right: $border-width solid $secondary-web-blue; color: $color-dark-grey; font-size: $fontSize-default; line-height: $lineHeight-default; font-family: $primary-font-regular; }
            td.mat-cell:last-child { border-right: 0; }
            .mat-column-select { text-align: center; }
        }
        /* tr:nth-child(even) { background: $color-light; }
        tr:nth-child(odd) { background: $color-blue; } */

        .mat-row:nth-child(2n+1) { background-color: $color-blue; }
        .mat-row:not(:nth-child(4n+1)) { background-color: $color-light; }
    }
}

ngx-recaptcha2 { margin: $gap-xs 0 $gap-xl; display: block; }

/* .title-wrapper {
    .description { font-size: $fontSize-xxl; line-height: $lineHeight-xxl; }
}

@media (max-width: 992px) {
    .title-wrapper {
        margin-bottom: $gap-lg;
        .description { font-size: $fontSize-xl; line-height: $lineHeight-xl; }
    }
} */
.title-wrapper {
    margin-bottom: $gap-lg;
    .description {  font-size: $fontSize-xl; line-height: $lineHeight-xl; font-family: 'PTSansRegular'; color: $primary-font-color; }
}

@media (min-width: $max-mobile-screen) {
    .title-wrapper {
        .description { font-size: $fontSize-xxl; line-height: $lineHeight-xxl; }
    }
}