@font-face {
    font-family: 'PTSansRegular';
    src: url('./assets/fonts/primary/PTSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'PTSansRegularItalic';
    src: url('./assets/fonts/primary/PTSans-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'PTSansBold';
    src: url('./assets/fonts/primary/PTSans-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'PTSansBoldItalic';
    src: url('./assets/fonts/primary/PTSans-BoldItalic.ttf') format('truetype');
}