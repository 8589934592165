@import './fonts.scss';

$primary-font-regular: 'PTSansRegular';
$primary-font-italic: 'PTSansRegularItalic';
$primary-font-bold: 'PTSansBold';
$primary-font-bolditalic: 'PTSansBoldItalic';

$primary-font-color: #00263E;
$secondary-font-color: #2B5A80;
$secondary-font-grey: #606363;

$primary-web-blue: #E2EEF8;
$primary-blue: #6FACDE;

$secondary-web-blue: #A9CDEB;
$secondary-grey-tint: #A1A4A4;

$color-light: #FFF;
$color-dark: #000;
$color-dark-grey: #444444;
$color-light-grey: #D0D1D1;
$color-grey-line : #DDDDDD;
$secondary-grey: #F3F4F4;
$secondary-grey-border: #BBBBBB;

$color-error: #B70000;
$color-error-background : #F8E6E6;
$color-blue: #F1F7FC;

$color-secondary-yellow:#FCF0D3;  
$font-secondary-yellow: #60480E;
$secondary-yellow-border: #F6D17B;
$yellow-border:#F0B323;

//Invoice screen loader colors
$loader-background: #E7E8E8;
$loader-color1: #e5e5e5;
$loader-color2: #f0f0f0;


/****** Variables for sizes - Start ********/

// font-size variables
$fontSize-xs: 0.625rem;         //10px
$fontSize-sm: 0.75rem;          //12px
$fontSize-md: 0.875rem;         //14px
$fontSize-default: 1rem;        //16px
$fontSize-lg: 1.125rem;         //18px
$fontSize-xl: 1.25rem;          //20px
$fontSize-xxl: 1.5rem;          //24px

// line-height variables
$lineHeight-xs: 1rem;           //16px
$lineHeight-sm: 1.25rem;        //20px
$lineHeight-default: 1.5rem;    //24px
$lineHeight-lg: 1.75rem;        //28px
$lineHeight-xl: 2rem;           //32px
$lineHeight-xxl: 2.5rem;        //40px

// font-weight variables
$fontWeight-default: 400;       //normal
$fontWeight-bold: 700;          //bold

// border variables
$border-width: 0.0625rem;       //1px
$border-radius: 0.25rem;        //4px

// padding & margin
$gap-xs: 0.5rem;                //8px
$gap-sm: 0.625rem;              //10px
$gap-md: 0.75rem;               //12px
$gap-default: 1rem;             //16px
$gap-lg: 1.5rem;                //24px
$gap-xl: 2rem;                  //32px
$gap-xxl: 2.5rem;               //40px

/****** Variables for sizes - End ********/
    
/******  screen size varibles  ********/
$mobile: 36rem;                // 576px
$max-mobile-screen: 62rem;     // 992px
$small-desktop: 75rem;         // 1200px